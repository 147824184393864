import { Vue, Component} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component({
  components: {}
})
export default class UnauthComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

}